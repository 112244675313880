<template>
  <div class="row">
    <div class="col-12">
      <q-form ref="editForm">
        <component
          :is="subComponent"
          :process.sync="process"
          :assessPlan.sync="assessPlan"
          :disabled.sync="disabled"
          :updateBtnData.sync="updateBtnData"
          :vendorFlag.sync="vendorFlag"
          @saveProcess="emitSaveProcess"
        />
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskTargetProcessDetail',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        processes: [], // 대상공정 목록
      }),
    },
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workingTime: 0,  // 작업시간(1회)
        vendorFlag: 0,
        temperature: null,
        workMeasurementPlanId: '',
        measPlanContent: '',
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        sops: [],
        drawNodes: [],
        draws: [],
        fmScenarios: [],
        hazopScenarios: [],
        scenarios: [],
        charmResultScenarios: [],
        charmEstimationScenarios: [],
        targets: [],
        thirdScenarios: [],
        safetyInfos: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    vendorFlag: {
      type: Boolean,
      default: () => ({
        flag: false,
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      // subComponent: () => import(`${'./4mTargetJobRisk.vue'}`),
      editable: true,
      saveUrl: '',
      isSave: false,
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd 
        && !this.disabled 
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    checkProcessCd() {
      return this.process.processCd.indexOf('WTE') === -1
    },
    subComponent() {
      let components = null;
      if (this.assessPlan.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        components = () => import(`${'@/pages/ram/hazop/plan/hazopTargetPidNode.vue'}`)
      } else if (this.assessPlan.ramTechniqueCd === 'RT00000025') {
        // 4M
        components = () => import(`${'@/pages/ram/4m/plan/4mTargetJobRisk.vue'}`)
      } else if (this.assessPlan.ramTechniqueCd === 'RT00000030') {
        // CHARM
        components = () => import(`${'@/pages/ram/charm/plan/charmTargetChem.vue'}`)
      }
      return components;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.ram.assessProcess.update.url
      // code setting
      // list setting
    },
    emitSaveProcess(processCd) {
      this.$emit('saveProcess', processCd)
    },
    searchWorkMeasure() {
      this.popupOptions.title = 'LBL0001078'; // 작업환경측정 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.plantCd,
        processCd: this.process.processCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/wmr/workMeasureResultPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkMeasurePopup;
    },
    closeWorkMeasurePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$set(this.process, 'workMeasurementPlanId', data[0].workMeasurementPlanId)
        this.$set(this.process, 'measPlanContent', data[0].measPlanContent)
      }
    },
    removeWorkMeasure() {
      this.$set(this.process, 'workMeasurementPlanId', null)
      this.$set(this.process, 'measPlanContent', null)
    },
    removeEquipment(item) {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGEXCEPT', // 제외하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.process.equipments = this.$_.reject(this.process.equipments, item)
        },
        // 취소 callback 함수
        cancelCallback: () => {
          item.check = true
        },
      });
    },
    addEquipment() {
      this.popupOptions.title = 'LBL0001079'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.process.equipments) {
          this.process.equipments = [];
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.equipments, { equipmentCd: item.equipmentCd }) === -1) {
            this.process.equipments.push({
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
            })
          }
        })
      }
    },
    clickTag(tag) {
      this.popupOptions.title = 'LBL0001080'; // 설비 상세
      this.popupOptions.param = tag;
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveProcess() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.process.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveProcessCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
    },
  }
};
</script>
