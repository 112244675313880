var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(_vm.subComponent, {
                tag: "component",
                attrs: {
                  process: _vm.process,
                  assessPlan: _vm.assessPlan,
                  disabled: _vm.disabled,
                  updateBtnData: _vm.updateBtnData,
                  vendorFlag: _vm.vendorFlag,
                },
                on: {
                  "update:process": function ($event) {
                    _vm.process = $event
                  },
                  "update:assessPlan": function ($event) {
                    _vm.assessPlan = $event
                  },
                  "update:assess-plan": function ($event) {
                    _vm.assessPlan = $event
                  },
                  "update:disabled": function ($event) {
                    _vm.disabled = $event
                  },
                  "update:updateBtnData": function ($event) {
                    _vm.updateBtnData = $event
                  },
                  "update:update-btn-data": function ($event) {
                    _vm.updateBtnData = $event
                  },
                  "update:vendorFlag": function ($event) {
                    _vm.vendorFlag = $event
                  },
                  "update:vendor-flag": function ($event) {
                    _vm.vendorFlag = $event
                  },
                  saveProcess: _vm.emitSaveProcess,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }